import { apiv1 } from "@/request/apiv1";
import qs from "qs";
import axios from "axios";

// 获取招商类型的所有数据
export function GetMerchantsTypesAll() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/merchants/types/all`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}


// 获取招商类型的分页列表
export function GetMerchantsTypesList(params = { page: 0, size: 20 }) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/merchants/types/page?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200 || res.status === 206) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 根据id获取具体的招商类型
export function GetMerchantsTypesById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/merchants/types/${id}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 新建招商类型
export function PostMerchantsType(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.post(`/merchants/types`, params)
            .then(res => {
                if (res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 修改指定招商类型的数据
export function PutMerchantsTypeById(id = "", params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/merchants/types/${id}`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 修改指定招商类型的数据
export function DeleteMerchantsTypeById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.delete(`/merchants/types/${id}`)
            .then(res => {
                if (res.status === 204) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 批量新增
export function MultiplePostMerchantsTypes(params = []) {
    return new Promise((resolve, reject) => {
        let multipleRequests = []
        params.forEach(item => {
            multipleRequests.push(apiv1.post(`/merchants/types`, item))
        })
        axios.all(multipleRequests)
            .then(axios.spread(() => {
                resolve();
            })).catch(err => {
                reject(err)
            });
    })

}

// 批量删除
export function MultipleDeleteMerchantsTypes(multipleSelectieIds = []) {
    return new Promise((resolve, reject) => {
        let multipleRequests = []
        multipleSelectieIds.forEach(item => {
            multipleRequests.push(apiv1.delete(`/merchants/types/${item}`))
        })
        axios.all(multipleRequests)
            .then(axios.spread(() => {
                resolve();
            })).catch(err => {
                reject(err)
            });
    })
}